import React, { useEffect, useState, FormEvent } from 'react';
import '../../assets/css/login/resetPasswordPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyResetToken } from '../../api/login/verrifyResetToken';
import { resetPassword } from '../../api/login/resetPassword';

// Define types for the response
interface ResetPasswordResponse {
  message: string;
}

interface ErrorResponse {
  message: string;
}

const ResetPassword: React.FC = () => {
  const { resetToken } = useParams<{ resetToken?: string }>(); // Token is a string, but could be undefined
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const navigate = useNavigate();

  console.log(resetToken); // Debug: show token in the console

  useEffect(() => {
    const checkTokenValidity = async () => {
      if (!resetToken) {
        setErrorMessage('Reset token is missing.');
        return;
      }

      try {
        const response: ResetPasswordResponse = await verifyResetToken(resetToken);
        console.log(response.message);
        setIsTokenValid(true);
      } catch (error: any) {
        console.error(error.message);
        setErrorMessage('The reset token is invalid or has expired.');
        setIsTokenValid(false);
      }
    };

    checkTokenValidity();
  }, [resetToken]);

  // If the token is invalid, show the error and navigate back
  if (!isTokenValid && errorMessage) {
    return (
      <div>
        <h2>Error</h2>
        <p>{errorMessage}</p>
        <button onClick={() => navigate('/forgot-password')}>Go Back</button>
      </div>
    );
  }

  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      setSuccessMessage('');
      return;
    }

    console.log("Sending request with:");
    console.log("newPassword:", newPassword);
    console.log("confirmPassword:", confirmPassword);

    if (!resetToken) {
      setErrorMessage('Reset token is missing.');
      return;
    }

    try {
      const response: ResetPasswordResponse = await resetPassword(resetToken, newPassword, confirmPassword);
      console.log('Password reset response:', response);
      setSuccessMessage('Your password has been successfully reset.');
      setErrorMessage('');
    } catch (error: any) {
      setErrorMessage(error.message || 'Error resetting password.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-left">
        <div className="logo">MID</div>
        <h2>Reset Password</h2>
        <p>Enter your new password to reset</p>
        <form onSubmit={handleResetPassword}>
          <div className="password-field">
            <input
              type={showNewPassword ? 'text' : 'password'}
              placeholder="New Password"
              className="password-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <div
              role="button"
              tabIndex={0}
              className="toggle-password"
              onClick={() => setShowNewPassword(!showNewPassword)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") setShowNewPassword(!showNewPassword);
              }}
            >
              👁
            </div>
          </div>

          <div className="password-field">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              className="password-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <div
              role="button"
              tabIndex={0}
              className="toggle-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              👁
            </div>
          </div>

          <button type="submit" className="validate-button">
            Reset Password
          </button>
          {successMessage && (
            <>
              <p className="success-message">{successMessage}</p>
              <a
                href="#"
                className="back-to-login-link"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');
                }}
              >
                Go to Login
              </a>
            </>
          )}
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <div className="reset-password-right">
        <div className="circle circle5"></div>
        <div className="circle circle4"></div>
        <div className="circle circle3"></div>
        <div className="circle circle2"></div>
        <div className="circle circle1"></div>
        <div className="background-overlay">
          <h1>MID Construction Group</h1>
          <p>Building The Future of Los Angeles</p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
