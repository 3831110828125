import { createAsyncThunk } from "@reduxjs/toolkit";

export interface INotification {
  enable: boolean;
  message?: string;
}

export interface IResponse {
  data: any;
  notif?: INotification;
}

interface IAsyncThunkCreatorProps {
  actionName: string;
  api: any;
  notif?: INotification;
}

export const asyncThunkCreator = (props: IAsyncThunkCreatorProps) => {
  const { actionName, api, notif } = props;
  return createAsyncThunk(
    actionName,
    async (params?: any): Promise<IResponse> => {
      const res = await api(params);
      console.log('RESPONSE:', res);
      return { 
        data: res , 
        notif: notif ?? undefined
      };
    }
  )
  // return createAsyncThunk(
  //   actionName,
  //   async (params: any, { rejectWithValue }) => {
  //     try {
  //       const res = await api(params);
  //         return { 
  //           data: res, 
  //           notif: notif ?? undefined
  //         };
  //     } catch (error) {
  //       // Return a custom error message with rejectWithValue
  //       return rejectWithValue(error || 'An error occurred');
  //     }
  //   }
  // );
}