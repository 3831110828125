import SideMenu from "../../components/users/SideMenu";
import Header from "../../components/users/Header";
import { useState, useEffect } from "react";
import '../../assets/css/Sow/sowPage.css';
import { searchSow } from '../../api/sow/sow';
import Pagination from '../../components/users/Pagination';
import SowList from "../../components/sow/SowList";

interface SowData {
  id: string;
  name: string;
  title: string;
  building_type: string;
  subcontract_value: number;
  alternate_subcontract_value: number;
  status: string;
}

interface SearchSowResponse {
  data: SowData[];
  totalPages: number;
}

const Sow: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>(''); // Typing searchTerm as string
  const [sows, setSows] = useState<SowData[]>([]); // Typing sows as an array of SowData objects
  const [currentPage, setCurrentPage] = useState<number>(1); // Typing currentPage as a number
  const [totalPages, setTotalPages] = useState<number>(1); // Typing totalPages as a number
  const [selectedSow, setSelectedSow] = useState<SowData | null>(null); // Typing selectedSow to either be a SowData object or null
  const [isModalVisible, setModelVisible] = useState<boolean>(false); // Typing modal visibility state

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onOpenSetting = () => {
    setModelVisible(false);
    setSelectedSow(null);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchSows = async () => {
      try {
        const res = await searchSow({
          query: searchTerm,
          page: currentPage,
        });
        const { data, totalPages }: SearchSowResponse = res?.data; // Assuming the API response structure
        setSows(data);
        setTotalPages(totalPages);
      } catch (error) {
        console.error('Error fetching SOWs:', error);
      }
    };

    fetchSows();
  }, [searchTerm, currentPage]);

  return (
    <div className="sow-page-layout">
      <SideMenu />
      <div className="content-wrapper">
        <Header
          title="Project"
          addButtonLabel="Add New SOW"
          searchPlaceholder="Search Sow"
          searchValue={searchTerm}
          onSearchChange={onSearchChange}
        />
        <SowList sows={sows} onOpenSetting={onOpenSetting} />
        <Pagination 
          currentPage={currentPage}
          totalPages={totalPages} 
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Sow;
