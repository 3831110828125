import { createSlice } from "@reduxjs/toolkit";
import * as Action from "./actions";
import * as State from "./state";

const SearchUsers = createSlice({
  name: "searchUsers",
  initialState: State.searchUsersState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.searchUserV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.searchUserV2Action.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.searchUserV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const CreateUser = createSlice({
  name: "createUser",
  initialState: State.createUserState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.createUserV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.createUserV2Action.fulfilled, (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.createUserV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const UpdateUser = createSlice({
  name: "updateUser",
  initialState: State.updateUserState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.updateUserV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.updateUserV2Action.fulfilled, (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.updateUserV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const DeleteUser = createSlice({
  name: "deleteUser",
  initialState: State.deleteUserState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.deleteUserV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.deleteUserV2Action.fulfilled, (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.deleteUserV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

export const { reset: resetSearchUsersState } = SearchUsers.actions;
export const { reset: resetCreateUserState } = CreateUser.actions;
export const { reset: resetUpdateUserState } = UpdateUser.actions;
export const { reset: resetDeleteUserState } = DeleteUser.actions;

export const searchUserV2 = SearchUsers.reducer;
export const createUserV2 = CreateUser.reducer;
export const updateUserV2 = UpdateUser.reducer;
export const deleteUserV2 = DeleteUser.reducer;
