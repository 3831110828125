import { ICommon, PageResult, ResultData } from "redux/helper/types";

export interface IProject {
  gross_building_up_area: number | null;
  lot_area: number | null;
  id: number;
  project_name: string;
  address: string;
  number_of_floors: number | null;
  rentable_area_residential: number | null;
  rentable_area_commercial: number | null;
  sow_count_pending: number | null;
  sow_count_completed: number | null;
  image_thumbnail?: string;
  status: boolean;
  project_users: number | null;
}

export const searchProjectsState: ICommon<PageResult<IProject[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const getAllProjectsState: ICommon<PageResult<IProject[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const createProjectState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const updateProjectState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const deleteProjectState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const searchUsersState: ICommon<PageResult<IProject[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}