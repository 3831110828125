import { ICommon, PageResult, ResultData } from "redux/helper/types";

export interface ICompany {
  address: string;
  contact_person: string;
  createdAt: string; 
  createdBy: number; 
  deletedAt: string | null; 
  deletedBy: number | null; 
  email: string;
  id: number;
  name: string;
  phone: string;
  updatedAt: string; 
  updatedBy: number | null;
}

export const getAllCompanyState: ICommon<PageResult<ICompany[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const searchCompanyState: ICommon<PageResult<ICompany[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const createCompanyState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const updateCompanyState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const deleteCompanyState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}
