import React from 'react';
import { Layout, Breadcrumb, Avatar, Typography, Dropdown, MenuProps } from 'antd';
import { BellOutlined, InfoCircleOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import styles from "./AppLayout.module.css";
import { useAppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { Title } = Typography;

const AppHeader: React.FC = () => {
  const { selectedMenuName } = useAppContext();
  const navigate = useNavigate();
  const generateBreadcrumbs = () => {
    return [selectedMenuName].map((key) => (
      <Breadcrumb.Item key={key}>
        {key.charAt(0).toUpperCase() + key.slice(1)}
      </Breadcrumb.Item>
    ));
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    navigate('/login');
  }

  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      key: '1',
      icon: <UserOutlined />
    },
    {
      label: 'Logout',
      key: '2',
      icon: <LogoutOutlined />
    },
  ];

  const handleMenuDropdownClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case "1":
        navigate('/profile');
        break;
      case "2":
        handleLogout();
        break;
      default:
        console.log(e)
        break;
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuDropdownClick,
  };
  
  return (
    <Header className={styles.customHeader}>
      {/* Breadcrumb Section */}
      <div>
        <Breadcrumb style={{ color: '#707EAE'}}>
          <Breadcrumb.Item>Pages</Breadcrumb.Item>
          {generateBreadcrumbs()}
        </Breadcrumb>
        <Title className={styles.customHeaderTitle}>
          {selectedMenuName}
        </Title>
      </div>

      {/* Icons and Avatar Section */}
      <div className={styles.customHeaderAction}>
        <BellOutlined style={{ fontSize: '20px', color: '#A3AED0' }} />
        <InfoCircleOutlined style={{ fontSize: '20px', color: '#A3AED0' }} />
        <Dropdown
          menu={menuProps}
          trigger={["click"]}
          placement="bottomRight"
        >
          <Avatar
            size="large"
            src="https://via.placeholder.com/40"
            style={{ cursor: "pointer" }}
          />
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
