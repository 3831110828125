import Sider from "antd/es/layout/Sider";
import { Image, Menu, MenuProps } from "antd";
import { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { DashboardOutlined } from '@ant-design/icons';
import { CompanyIcon, DepartmentIcon, ProjectIcon, SowTemplateIcon, SubContractorIcon, TradesIcon, UsersIcon } from "components/customIcons/CustomIcons";
import { useAppContext } from "./AppContext";

export interface CustomMenuItem extends Omit<MenuProps["items"], "children"> {
  key: string;
  name: string; // Custom property for menu name
  children?: CustomMenuItem[]; // Allow nested submenus
  label: React.ReactNode;
  icon: React.ReactNode;
}

const AppSidebar: React.FC = () => {
  const { selectedMenu, setSelectedMenu, setSelectedMenuName } = useAppContext();
  const location = useLocation();

  const menuItems: CustomMenuItem[] = useMemo(() => [
    {
      key: '/dashboard',
      name: 'Dashboard',
      label: <Link to="/dashboard">Dashboard</Link>,
      icon: <DashboardOutlined />
    },
    {
      key: '/projects',
      name: 'Projects',
      label: <Link to="/projects">Projects</Link>,
      icon: <ProjectIcon />
    },
    {
      key: '/sow-templates',
      name: 'SOW Templates',
      label: <Link to="/sow-templates">SOW Templates</Link>,
      icon: <SowTemplateIcon />
    },
    {
      key: '/trades',
      name: 'Trades',
      label: <Link to="/trades">Trades</Link>,
      icon: <TradesIcon />
    },
    {
      key: '/users',
      name: 'Users',
      label: <Link to="/users">Users</Link>,
      icon: <UsersIcon />
    },
    {
      key: '/departments',
      name: 'Departments',
      label: <Link to="/departments">Departments</Link>,
      icon: <DepartmentIcon />
    },
    {
      key: '/company',
      name: 'Company',
      label: <Link to="/company">Company</Link>,
      icon: <CompanyIcon />
    },
    {
      key: '/sow-contractors',
      name: 'Subcontractors',
      label: <Link to="/sow-contractors">Subcontractors</Link>,
      icon: <SubContractorIcon />
    },
  ], []);

  useEffect(() => {
    const menu = (menuItems ?? []).find((el): el is CustomMenuItem => el?.key === location.pathname)
    if (menu && menu.key) {
      setSelectedMenu([menu.key]);
      setSelectedMenuName(menu.name);
    }
  },[location, location.pathname, menuItems, setSelectedMenu, setSelectedMenuName]);

  return (
    <Sider width={220} theme="light">
      <div>
        <Image
          src={require("../../assets/img/logo.png")}
          alt='Logo'
          preview={false}
        />
      </div>
      <Menu 
        theme="light" 
        mode="inline" 
        selectedKeys={selectedMenu}
        items={menuItems}
      >
      </Menu>
    </Sider>
  )
}

export default AppSidebar;
