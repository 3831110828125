import { ICommon, PageResult, ResultData } from "redux/helper/types";

export interface IUser {
  authorizeAllProject: number;
  companyId: number | null;
  createdAt: string; 
  deletedAt: string | null; 
  deletedBy: number | null;
  department_id: number;
  email: string;
  id: number;
  is_active: number; 
  last_name: string;
  name: string;
  password: string;
  role_id: string; 
  role_type: number;
  updatedAt: string; 
}

export const searchUsersState: ICommon<PageResult<IUser[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null,
}

export const createUserState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null,
}

export const updateUserState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null,
}

export const deleteUserState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null,
}