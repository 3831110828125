import { ICommon, PageResult, ResultData } from "redux/helper/types";

export interface ITrade {
  added_by: number;
  approvalDate: string; 
  approvalStatus: string; 
  cost_code_tier2: string;
  created_at: string; 
  deletedAt: string | null; 
  deletedBy: string | null; 
  description: string;
  divisionName: string;
  id: number; 
  name: string; 
  phase_no: string;
  prepClosedDate: string; 
  prepStatus: string;
  project_name: string;
  reviewApprovalBy: string; 
  status: number; 
  subcontract_id: string;
  underPrepBy: string;
  updated_at: string; 
  updated_by: string | null; 
}

export const getAllTradeState: ICommon<PageResult<ITrade[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const searchTradeState: ICommon<PageResult<ITrade[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const createTradeState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const updateTradeState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const deleteTradeState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}
