import apiClient from '../login/apiClient';
import * as fetcher from '../httpHelper';

interface SowResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export const createProject = async (params: Record<string, any>): Promise<any> => {
  const token = localStorage.getItem('accessToken'); 

  console.log('Sending request with token:', token); 

  if (!token) {
    alert('Access token is missing.');
    return; 
  }

  try {
    const response = await apiClient.post('/api/projects', params, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    console.log('Project created successfully:', response.data); 
    return response.data;

  } catch (error: any) {
    handleError(error, 'Failed to create project');
  }
};

export const searchProjects = async (params: any): Promise<any> => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.get(`/api/projects/search?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Projects fetched successfully:', response.data);
    return response.data;

  } catch (error: any) {
    handleError(error, 'Failed to fetch projects');
  }
};

export const deleteProject = async (id: number): Promise<any> => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.delete(`/api/projects/id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Project deleted successfully:', response.data);
    return response.data;

  } catch (error: any) {
    handleError(error, 'Failed to delete project');
  }
};

export const updateProject = async (id: number, params: Record<string, any>): Promise<any> => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/projects/${id}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    handleError(error, 'Failed to update project');
  }
};

const handleError = (error: any, defaultMessage: string): SowResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
      return { success: false, error: 'Unauthorized' };
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data.message || defaultMessage };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};

export const searchProjectsV2 = (params: any) => {
  return fetcher.GetFetcher('/api/projects/search', params);
}

export const createProjectV2 = (params: any) => {
  return fetcher.PostMultipart('/api/projects', params);
}

export const updateProjectV2 = (params: any) => {
  return fetcher.PostMultipart(`/api/projects/${params.id}`, params);
}

export const deleteProjectV2 = (id: number) => {
  return fetcher.DeleteFetcher(`/api/projects/${id}`);
}

export const getAllProjects = (params: any) => {
  return fetcher.GetFetcher('/api/projects', params);
}