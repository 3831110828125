import { asyncThunkCreator } from "../helper/asyncThunkCreator";
import { searchUsersV2, createUserV2, updateUserV2, deleteUserV2 } from "../../api/users/users";

export const searchUserV2Action = asyncThunkCreator({
  actionName: "users/searchUser",
  api: searchUsersV2
});

export const createUserV2Action = asyncThunkCreator({
  actionName: "users/create",
  api: createUserV2,
  notif: { enable: true, message: 'Succesfully created.' }
});

export const updateUserV2Action = asyncThunkCreator({
  actionName: "users/update",
  api: updateUserV2,
  notif: { enable: true, message: 'Succesfully updated.' }
});

export const deleteUserV2Action = asyncThunkCreator({
  actionName: "users/delete",
  api: deleteUserV2,
  notif: { enable: true, message: 'Succesfully deleted.' }
});