import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkProtectedRoute } from '../../api/login/checkProtectedRoute'; 
import '../../assets/css/login/layout.css';
import '../../assets/css/login/login.css';

const HomePage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleCheckProtectedRoute = async () => {
    try {
      const data = await checkProtectedRoute(); 
      setMessage(data.message); 
      setError('');
      console.log('No error');
      // navigate('/protectedPage'); // Uncomment to navigate to the protected page
    } catch (err: any) {
      if (err.response?.status === 401) {
        // If there's an issue with the token (Unauthorized)
        // navigate('/login'); // Uncomment to navigate to the login page
      } else {
        setError('Error: ' + (err.response?.data?.message || err.message));
        setMessage('');
      }
    }
  };

  return (
    <div>
      <h1>Welcome to the Home Page</h1>
      <button onClick={handleCheckProtectedRoute}>Check Protected Route</button>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default HomePage;
