import apiClient from '../login/apiClient';
import * as fetcher from '../httpHelper';

interface SowResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export const createTrade = async (params: any) => {

  try {
    const response = await apiClient.post('/api/trades', params);

    console.log('Trade created successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to create trade');
  }
};

export const searchTrade = async (params: any) => {
  console.log('trades', params)
  try {
    const response = await apiClient.get(`/api/trades/search?${params}`, {
    });

    console.log('Trades fetched successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to fetch trades');
  }
};

export const updateTrade = async (id: number, params: any) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/trades/${id}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Trade updated successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to update trade');
  }
};


export const deleteTrade = async (id: number) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.delete(`/api/trades/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Trade deleted successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to delete trade');
  }
};


const handleError = (error: any, defaultMessage: string): SowResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
      return { success: false, error: 'Unauthorized' };
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data.message || defaultMessage };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};

export const getAllTrades = (params: any) => {
  return fetcher.GetFetcher("/api/trades", params);
}

export const searchTradeV2 = (params: any) => {
  return fetcher.GetFetcher("/api/trades", params);
}

export const createTradeV2 = (params: any) => {
  return fetcher.PostFetcher("/api/trades", params);
}

export const updateTradeV2 = (params: any) => {
  return fetcher.PutFetcher(`/api/trades/${params.id}`, params);
}

export const deleteTradeV2 = (id: number) => {
  return fetcher.DeleteFetcher(`/api/trades/${id}`);
}