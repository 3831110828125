import{ DragEvent, useState } from "react";
import '../../assets/css/Sow/dragAndDropComponent.css';

const DragAndDrop = () => {
  const [items, setItems] = useState([
    { type: "Title", content: "Enter your title here", settings: { fontSize: "24px", bold: true } },
    { type: "DateTime", content: "MM/DD/YYYY HH:mm", settings: { format: "MM/DD/YYYY HH:mm" } },
    { type: "List", content: ["Item 1", "Item 2", "Item 3"], settings: { bulletType: "circle" } },
    { type: "Signature", content: "Signature Placeholder", settings: {} },
    { type: "Text", content: "Enter your text here", settings: { fontSize: "16px" } },
    { type: "Dropdown", content: ["Option 1", "Option 2"], settings: {} },
    { type: "Checkbox", content: "Check this box", settings: {} },
    { type: "Radio", content: "Select an option", settings: {} },
    { type: "Image", content: "Image Placeholder", settings: {} },
  ]);

  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const onDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    setDraggedItemIndex(index);
    e.currentTarget.classList.add("dragging");
    e.dataTransfer.setData("application/json", JSON.stringify(items[index]));
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>, index: number | null) => {
    e.preventDefault();
    if (draggedItemIndex === index) return;

    const updatedItems = [...items];
    const draggedItem = draggedItemIndex ? updatedItems.splice(draggedItemIndex, 1)[0] : 0;
    if (index && draggedItem) updatedItems.splice(index, 0, draggedItem);

    setItems(updatedItems);
    setDraggedItemIndex(index);
  };

  const onDragEnd = (e: DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove("dragging");
    setDraggedItemIndex(null);
  };

  return (
    <div className="drag-container">
      <h2>Drag & Drop Items</h2>
      <div className="drag-items">
        {items.map((item, index) => (
          <div
            key={index}
            className="drag-item"
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDragOver={(e) => onDragOver(e, index)}
            onDragEnd={(e) => onDragEnd(e)}
          >
            <i className="fas fa-grip-dots drag-handle"></i>
            {item.type} {/* הצגת סוג הפריט */}
          </div>
        ))}
      </div>
      <button className="import-btn">Import Template</button>
    </div>
  );
};


export default DragAndDrop;
