import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.NODE_ENV === "production" 
  ? process.env.REACT_APP_API_URL 
  : process.env.REACT_APP_API_LOCAL,
});


apiClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;

      if (status === 401) {
        console.warn("Unauthorized: Redirecting to login...");
        window.location.href = '/login'; 
        return Promise.reject("Unauthorized. Redirecting to login...");
      }

      if (status === 400) {
        console.error("Bad Request: Check input parameters.");
        alert("Bad Request: Please check the input parameters.");
        return Promise.reject("Bad Request");
      }

      if (status === 500) {
        console.error("Internal Server Error: Returning the response.");
        return error.response;
      }
    }

    console.error("Error: ", error.message);
    return Promise.reject(error.response);
  }
);

const GetFetcher = async (url: string, params?: any) => {
  const config = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    params,
  };

  return await apiClient(url, config)
  .then(response => response.data)
  .catch((error: any) => error);
};

const PostFetcher = async (url: string, params?: any) => {
  const config = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    data: params,
  };

  return await apiClient(url, config)
  .then(response => response.data)
  .catch((error: any) => error);
};

const PostMultipart = async (url: string, params: any) => {
  const config = {
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Requested-With": "XMLHttpRequest",
      accept: "multipart/form-data",
    },
    data: params,
  };

  return await apiClient(url, config)
  .then(response => response.data)
  .catch((error: any) => error);
};

const PutFetcher = async (url: string, params?: any) => {
  const config = {
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    data: params,
  };

  return await apiClient(url, config)
  .then(response => response.data)
  .catch((error: any) => error);
};

const DeleteFetcher = async (url: string, params?: any) => {
  const config = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    data: params,
  };

  return await apiClient(url, config)
  .then(response => response.data)
  .catch((error: any) => error);
};

export {
  GetFetcher,
  PostFetcher,
  PostMultipart,
  PutFetcher,
  DeleteFetcher
}