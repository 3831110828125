import apiClient from '../login/apiClient';
import * as fetcher from '../httpHelper';

interface SowResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export const createRawItem = async (params: Record<string, any>): Promise<any> => {
  const userId = localStorage.getItem('userId');

  const rawItemPayload = {
    ...params,
    created_by: Number(userId),
  };

  try {
    const response = await apiClient.post('/api/rawItems', rawItemPayload);

    console.log('Raw Item created successfully:', response.data);
    return response.data;

  } catch (error: any) {
    console.error('Error creating raw item:', error.message);
    alert('Failed to create raw item. Please try again later.');
    return;
  }
};

export const searchRawItems = async (params: Record<string, any>): Promise<any> => {
  try {
    const response = await apiClient.get('/api/rawItems/search', { params });

    console.log('Raw Items fetched successfully:', response.data);
    
    return {
      data: response.data.items, 
      totalPages: response.data.totalPages,
    };

  } catch (error: any) {
    console.error('Error searching raw items:', error.message);
    alert('Failed to fetch raw items. Please try again later.');
    return { data: [], totalPages: 1 };
  }
};

export const getAllRawItems = async (page = 1, pageSize = 10): Promise<any> => {
  try {
    const response = await apiClient.get('/api/rawItems/', {
      params: { page, pageSize },
    });

    return {
      data: response.data.items, 
      totalPages: response.data.totalPages,
    };

  } catch (error: any) {
    console.error('Error fetching raw items:', error.message);
    throw new Error(error.response?.data?.message || 'Failed to fetch raw items');
  }
};


export const deleteRawItem = async (id: number): Promise<any> => {
  try {
    const response = await apiClient.delete(`/api/rawItems/${id}`);

    console.log('Raw Item deleted successfully:', response.data);
    return response.data;
  } catch (error: any) {
    console.error('Error deleting raw item:', error.message);
    alert('Failed to delete raw item. Please try again.');
    throw error;
  }
};

export const updateRawItem = async (id: number, params: Record<string, any>): Promise<any> => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/rawItems/${id}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Raw Item updated successfully:', response.data);
    return response.data;

  } catch (error: any) {
    handleError(error, 'Failed to update raw item');
  }
};

const handleError = (error: any, defaultMessage: string): SowResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
      return { success: false, error: 'Unauthorized' };
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data.message || defaultMessage };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};

export const getAllRawItemsV2 = (params: any) => {
  return fetcher.GetFetcher("/api/rawItems", params);
}

export const searchRawItemsV2 = (params: any) => {
  return fetcher.GetFetcher("/api/rawItems", params);
}

export const createRawItemsV2 = (params: any) => {
  return fetcher.PostMultipart("/api/rawItems", params);
}

export const updateRawItemsV2 = (params: any) => {
  return fetcher.PutFetcher(`/api/rawItems/${params.is}`, params);
}

export const deleteRawItemsV2 = (id: number) => {
  return fetcher.DeleteFetcher(`/api/rawItems/${id}`);
}
