import { createSlice } from "@reduxjs/toolkit";
import * as Action from "./actions";
import * as State from "./state";

const GetAllDepartments = createSlice({
  name: "getAllDepartments",
  initialState: State.getAllDepartmentState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.getAllDepartmentsAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.getAllDepartmentsAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.getAllDepartmentsAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const SearchDepartments = createSlice({
  name: "searchDepartments",
  initialState: State.searchDepartmentsState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.searchDepartmentsAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.searchDepartmentsAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.searchDepartmentsAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const CreateDepartment = createSlice({
  name: "createDepartment",
  initialState: State.createDepartmentState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.createDepartmentAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.createDepartmentAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.createDepartmentAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const UpdateDepartment = createSlice({
  name: "updateDepartment",
  initialState: State.updateDepartmentState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.updateDepartmentAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.updateDepartmentAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.updateDepartmentAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const DeleteDepartment = createSlice({
  name: "deleteDepartment",
  initialState: State.deleteDepartmentState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.deleteDepartmentAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.deleteDepartmentAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.deleteDepartmentAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

export const { reset: resetCreateDepartmentState } = CreateDepartment.actions;
export const { reset: resetUpdateDepartmentState } = UpdateDepartment.actions;
export const { reset: resetDeleteDepartmentState } = DeleteDepartment.actions;

export const getAllDepartments = GetAllDepartments.reducer;
export const searchDepartments = SearchDepartments.reducer;
export const createDepartment = CreateDepartment.reducer;
export const updateDepartment = UpdateDepartment.reducer;
export const deleteDepartment = DeleteDepartment.reducer;
