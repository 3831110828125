import { ICommon, PageResult, ResultData } from "redux/helper/types";

export interface IRawItem {
  id: number;
  item_name: string;
  item_description: string | null;
  is_available: number;
  status: number;
  image: string | null;
  updated_at: string | null;
}

export const getAllRawItemsState: ICommon<PageResult<IRawItem[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const searchRawItemState: ICommon<PageResult<IRawItem[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const createRawItemState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const updateRawItemState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const deleteRawItemState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}
