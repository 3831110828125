import { asyncThunkCreator } from "../helper/asyncThunkCreator";
import { 
  searchProjectsV2, 
  createProjectV2, 
  updateProjectV2, 
  deleteProjectV2, 
  getAllProjects 
} from "../../api/project/project";

export const searchProjectsV2Action = asyncThunkCreator({
  actionName: "projects/search",
  api: searchProjectsV2
});

export const createProjectV2Action = asyncThunkCreator({
  actionName: "projects/create",
  api: createProjectV2,
  notif: { enable: true, message: 'Succesfully created.' }
});

export const updateProjectV2Action = asyncThunkCreator({
  actionName: "projects/update",
  api: updateProjectV2,
  notif: { enable: true, message: 'Succesfully updated.' }
});

export const deleteProjectV2Action = asyncThunkCreator({
  actionName: "projects/delete",
  api: deleteProjectV2,
  notif: { enable: true, message: 'Succesfully deleted.' }
});

export const getAllProjectsAction = asyncThunkCreator({
  actionName: "projects/getAll",
  api: getAllProjects
});