import apiClient from './apiClient';
import refreshToken from './refreshToken';

interface LoginParams {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  userdetails: {
    id?: string;
    [key: string]: any;
  };
}

export interface ProtectedDataResponse {
  [key: string]: any;
}

export const login = async (params: LoginParams): Promise<LoginResponse> => {
  try {
    const response = await apiClient.post<LoginResponse>(`/api/auth/login`, params);

    const { accessToken, refreshToken, userdetails } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('userDetails', JSON.stringify(userdetails));

    if (userdetails?.id) {
      localStorage.setItem('userId', userdetails.id.toString());
    } else {
      console.warn('User ID is missing in login response.');
    }

    console.log('Login successful. Tokens saved in Local Storage.');
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 403 && error.response?.data?.locked) {
      throw new Error('Account is locked. Try again in 30 minutes.');
    } else {
      throw new Error(error.response?.data?.message || 'Login failed');
    }
  }
};

// Updated fetchProtectedData function with proper return type
export const fetchProtectedData = async (): Promise<ProtectedDataResponse> => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No access token found. Please login again.');
    }

    const response = await apiClient.get<ProtectedDataResponse>('/api/protected-route', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;  // Return the actual data, not the full response
  } catch (error: any) {
    if (error.response?.status === 401) {
      console.log('Access token expired. Attempting to refresh the token.');
      
      try {
        const newAccessToken = await refreshToken();
        const response = await apiClient.get<ProtectedDataResponse>('/api/protected-route', {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        });

        return response.data;  // Return the actual data
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        throw new Error('Failed to refresh token. Please login again.');
      }
    } else {
      throw new Error('Failed to fetch protected data.');
    }
  }
};

