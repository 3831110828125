import React, { Suspense } from 'react';
import { Layout, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import styles from "./AppLayout.module.css";
import AppHeader from './AppHeader';
import { AppProvider } from './AppContext';
import AppSidebar from './AppSidebar';

const { Content } = Layout;

const AppLayout: React.FC = () => {
  return (
    <AppProvider>
      <Layout className={styles.customSidebar} style={{ minHeight: '100vh' }}>
        {/* Sidebar */}
        <AppSidebar />

        {/* Main Content */}
        <Layout>
          <AppHeader />
          <Content className={styles.customContent}>
            <Suspense fallback={<Spin size="large" style={{ display: 'block', margin: 'auto' }} />}>
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </AppProvider>
  );
};

export default AppLayout;
