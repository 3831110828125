import { useState, useEffect } from 'react';
import SideMenu from '../../components/users/SideMenu';
import Header from '../../components/users/Header';
// import NewSowTemplate from '../../components/sow/';
import '../../assets/css/Sow/newSowTemplatePage.css';

const NewSowTemplatesPage = () => {
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    // Fetch trades from the server
    fetch('/api/trades')
      .then((response) => response.json())
      .then((data) => setTrades(data))
      .catch((error) => console.error('Error fetching trades:', error));
  }, []);

  return (
    <div className="sow-page-layout">
      <SideMenu />
      <div className="main-content">
        <Header
          title="SOW Templates"
          addButtonLabel="Add New Template"
          showAddButton={true}
          showSearch={false}
        />
        {/* Pass trades as props to the NewSowTemplate component */}
        {/* <NewSowTemplate trades={trades} /> */}
      </div>
    </div>
  );
};

export default NewSowTemplatesPage;
