import { asyncThunkCreator } from "../helper/asyncThunkCreator";
import { 
  getAllDepartments,
  searchDepartmentV2,
  createDepartmentV2,
  updateDepartment,
  deleteDepartment
} from "../../api/Department/department";

export const getAllDepartmentsAction = asyncThunkCreator({
  actionName: "departments/getAll",
  api:  async (params:any) => {
    try {
      const response = await getAllDepartments(params);
      return response.data;
    } catch (error: any) {
      throw error.response?.data || error.message;
    }
  }
});


export const searchDepartmentsAction = asyncThunkCreator({
  actionName: "departments/search",
  api: async (searchParams: any) => {
    try {
      const response = await searchDepartmentV2(searchParams);
      return response.data;
    } catch (error: any) {
      throw error.response?.data || error.message;
    }
  }
});

export const createDepartmentAction = asyncThunkCreator({
  actionName: "departments/create",
  api:  async (departmentData: any) => {
    try {
      const response = await createDepartmentV2(departmentData);
      return response.data;
    } catch (error: any) {
      throw error.response?.data || error.message;
    }
  },
  notif: { enable: true, message: "Successfully created." }
});

export const updateDepartmentAction = asyncThunkCreator({
  actionName: "departments/update",
  api: async (departmentData: any) => {
    try {
      const response = await updateDepartment(departmentData);
      return response.data;
    } catch (error: any) {
      throw error.response?.data || error.message;
    }
  },
  notif: { enable: true, message: "Successfully updated." }
});

export const deleteDepartmentAction = asyncThunkCreator({
  

  actionName: "departments/delete",
    api: async (departmentId: number) => {
      try {
        const response = await deleteDepartment(departmentId);
        return response.data;
      } catch (error: any) {
        throw error.response?.data || error.message;
      }
    },
    notif: { enable: true, message: "Successfully deleted." }
  });