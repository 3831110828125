import apiClient from '../login/apiClient';
import * as fetcher from '../httpHelper';

interface SowResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export const createCompany = async (params: { name: string, address: string, email: string }) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  if (!params.name || !params.address || !params.email) {
    alert('Missing required fields: name, address, and email.');
    return;
  }

  try {
    const response = await apiClient.post('/api/companies', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Company created successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to create company');
  }
};

export const searchCompany = async (params: any) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.get(`/api/companies/search?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Companies fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch companies');
  }
};

const handleError = (error: any, defaultMessage: string): SowResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
      return { success: false, error: 'Unauthorized' };
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data.message || defaultMessage };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};

export const getAllCompany = (params: any) => {
  return fetcher.GetFetcher("/api/companies", params);
}

export const searchCompanyV2 = (params: any) => {
  return fetcher.GetFetcher("/api/companies", params);
}

export const createCompanyV2 = (params: any) => {
  return fetcher.PostFetcher("/api/companies", params);
}

export const updateCompany = (params: any) => {
  const id = params.get('id');
  console.log(`/api/companies/${id}`);
  return fetcher.PutFetcher(`/api/companies/${id}`, params);
}

export const deleteCompany = (id: number) => {
  return fetcher.DeleteFetcher(`/api/companies/${id}`);
}
