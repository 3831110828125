import { asyncThunkCreator } from "../helper/asyncThunkCreator";
import { 
  getAllCompany,
  searchCompanyV2,
  createCompanyV2,
  updateCompany,
  deleteCompany
} from "../../api/companies/company";

export const getAllCompanyAction = asyncThunkCreator({
  actionName: "company/getAll",
  api: getAllCompany
});

export const searchCompanyAction = asyncThunkCreator({
  actionName: "company/search",
  api: searchCompanyV2
})

export const createCompanyAction = asyncThunkCreator({
  actionName: "company/create",
  api: createCompanyV2,
  notif: { enable: true, message: 'Succesfully created.' }
})

export const updateCompanyAction = asyncThunkCreator({
  actionName: "company/update",
  api: updateCompany,
  notif: { enable: true, message: 'Succesfully updated.' }
})

export const deleteCompanyAction = asyncThunkCreator({
  actionName: "company/delete",
  api: deleteCompany,
  notif: { enable: true, message: 'Succesfully deleted.' }
})