import apiClient from './apiClient';

// פונקציה לשליחת הסיסמה החדשה לשרת
export const resetPassword = async (
  token: string,
  newPassword: string,
  confirmPassword: string
): Promise<any> => {
  try {
    const response = await apiClient.post(`/api/auth/reset-password/${token}`, {
      newPassword,
      confirmPassword
    });
    return response.data; // מחזיר את תשובת השרת במידה והבקשה מצליחה
  } catch (error: any) {
    throw error.response?.data?.message || 'Error resetting password'; // מחזיר הודעת שגיאה במקרה של כישלון
  }
};
