import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducers";
import notificationMiddleware from "./middlerware/notificationMiddleware";

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(notificationMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
