import React from 'react';
import '../../assets/css/userPage/userPage.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handleFirstPage = () => onPageChange(1);
  const handleLastPage = () => onPageChange(totalPages);
  const handleNextPage = () => onPageChange(currentPage + 1);
  const handlePreviousPage = () => onPageChange(currentPage - 1);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`pagination-btn ${i === currentPage ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination-container">
      <button
        onClick={handleFirstPage}
        disabled={currentPage === 1}
        className="pagination-btn"
      >
        « First
      </button>
      <button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        className="pagination-btn"
      >
        ‹ Back
      </button>

      {renderPageNumbers()}

      <button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        className="pagination-btn"
      >
        Next ›
      </button>
      <button
        onClick={handleLastPage}
        disabled={currentPage === totalPages}
        className="pagination-btn"
      >
        Last »
      </button>
    </div>
  );
};

export default Pagination;
