import { createSlice } from "@reduxjs/toolkit";
import * as Action from "./actions";
import * as State from "./state";

const GetAllTrades = createSlice({
  name: "getAllTrades",
  initialState: State.getAllTradeState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.getAllTradesAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.getAllTradesAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.getAllTradesAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const GetSearchTrade= createSlice({
  name: "searchTrade",
  initialState: State.searchTradeState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.searchTradeAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.searchTradeAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.searchTradeAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const CreateTrade = createSlice({
  name: "createTrade",
  initialState: State.createTradeState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.createTradeAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.createTradeAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.createTradeAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const UpdateTrade = createSlice({
  name: "updateTrade",
  initialState: State.updateTradeState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.updateTradeAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.updateTradeAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.updateTradeAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const DeleteTrade = createSlice({
  name: "deleteTrade",
  initialState: State.deleteTradeState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.deleteTradeAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.deleteTradeAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.deleteTradeAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

export const { reset: resetCreateTradeState } = CreateTrade.actions;
export const { reset: resetUpdateTradeState } = UpdateTrade.actions;
export const { reset: resetDeleteTradeState } = DeleteTrade.actions;

export const getAllTrades = GetAllTrades.reducer;
export const searchTrades = GetSearchTrade.reducer;
export const createTrade = CreateTrade.reducer;
export const updateTrade = UpdateTrade.reducer;
export const deleteTrade = DeleteTrade.reducer;
