import React, { useState, ChangeEvent } from 'react';
import '../../assets/css/userPage/header.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
  title: string;
  searchPlaceholder?: string;
  searchValue?: string;
  onSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  addButtonLabel?: string;
  ModalComponent?: React.ComponentType<{ isOpen: boolean; onClose: () => void }>;
  showSearch?: boolean;
  showAddButton?: boolean;
  showRightIcons?: boolean;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({
  title,
  searchPlaceholder = 'Search...',
  searchValue = '',
  onSearchChange,
  addButtonLabel = 'Add',
  ModalComponent,
  showSearch = true,
  showAddButton = true,
  showRightIcons = true,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleAddUserClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <header className="topbar">
      <div className="header-top">
        <h1 className="header-title">{title}</h1>
        {showRightIcons && (
          <div className="header-right-container">
            <div className="header-right">
              <i className="fas fa-bell notification-icon"></i>
              <i className="fas fa-info-circle info-icon"></i>
              <div className="profile-icon">
                <Link to="/profile">
                  <FontAwesomeIcon icon={faUser} size="1x" />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="divider"></div>
      <div className="header-bottom">
        {showSearch && (
          <div className="search-bar">
            <i className="fas fa-search search-icon"></i>
            <input
              type="text"
              value={searchValue}
              onChange={onSearchChange}
              placeholder={searchPlaceholder}
            />
          </div>
        )}
        {showAddButton && (
          <button className="add-user-btn" onClick={handleAddUserClick}>
            <i className="fas fa-plus"></i> {addButtonLabel}
          </button>
        )}
      </div>

      {ModalComponent && isModalOpen && (
        <ModalComponent isOpen={isModalOpen} onClose={closeModal} />
      )}
    </header>
  );
};

export default Header;
