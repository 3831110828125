import { notification } from 'antd';
import { Middleware } from '@reduxjs/toolkit'; 
import { IResponse } from '../helper/asyncThunkCreator';

const notificationMiddleware: Middleware = () => (next) => (action: any) => {
  if (action.payload === undefined) return;
  const { data, notif }: IResponse = action.payload;
  console.log(data, notif)

  // if (data) {
  //   if (
  //     data &&
  //     Object.prototype.hasOwnProperty.call(data, "status") &&
  //     Object.prototype.hasOwnProperty.call(data, "data")
  //   ) {
  //     console.log(notif)
  //     // Handle server error
  //     const statusCode = data.status;
  //     if (statusCode === 500 && Object.prototype.hasOwnProperty.call(data.data, "error")) {
  //       notification.error({
  //         message: "Server Error.",
  //         description: data.data.error || "Server Error.",
  //         duration: 3
  //       })
  //     } else if (
  //       notif && notif?.enable
  //       && Object.prototype.hasOwnProperty.call(data.data, "success")
  //       && data.data.success === true
  //     ) {
  //       notification.success({
  //         message: "Success",
  //         description: notif.message,
  //         duration: 3
  //       })
  //     } else {
  //       const errors: string[] = [];
  //       if (data.data && data.data && Object.prototype.hasOwnProperty.call(data.data, "message")) {
  //         errors.push(data.data.message);
  //       }
  //       notification.error({
  //         message: "Error",
  //         description: errors,
  //         duration: 3
  //       })
  //     }
  //   } 
  // }
  if (data) {
    const { message, success} = data;
    if (success === false) {
      notification.error({
        message: "Error",
        description: message,
        duration: 3
      })
    }
  }

  if (data && notif !== undefined) {
    const { message, success} = data;
    if (notif.enable && success) {
      notification.success({
        message: "Success",
        description: notif.message || message,
        duration: 3
      })
    }
  }
  next(action);
};

export default notificationMiddleware;
