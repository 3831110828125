import { createSlice } from "@reduxjs/toolkit";
import * as Action from "./actions";
import * as State from "./state";

const GetAllCompany = createSlice({
  name: "getAllCompany",
  initialState: State.getAllCompanyState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.getAllCompanyAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.getAllCompanyAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.getAllCompanyAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const GetSearchCompany = createSlice({
  name: "searchCompany",
  initialState: State.searchCompanyState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.searchCompanyAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.searchCompanyAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.searchCompanyAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const CreateCompany = createSlice({
  name: "createCompany",
  initialState: State.createCompanyState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.createCompanyAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.createCompanyAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.createCompanyAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const UpdateCompany = createSlice({
  name: "updateCompany",
  initialState: State.updateCompanyState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.updateCompanyAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.updateCompanyAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.updateCompanyAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const DeleteCompany = createSlice({
  name: "deleteCompany",
  initialState: State.deleteCompanyState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.deleteCompanyAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.deleteCompanyAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.deleteCompanyAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

export const { reset: resetCreateCompanyState } = CreateCompany.actions;
export const { reset: resetUpdateCompanyState } = UpdateCompany.actions;
export const { reset: resetDeleteCompanyState } = DeleteCompany.actions;

export const getAllCompany = GetAllCompany.reducer;
export const searchCompany = GetSearchCompany.reducer;
export const createCompany = CreateCompany.reducer;
export const updateCompany = UpdateCompany.reducer;
export const deleteCompany = DeleteCompany.reducer;
