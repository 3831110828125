import { combineReducers } from "@reduxjs/toolkit";
import * as users from "./users/slicer";
import * as company from "./company/slicer";
import * as departments from "./departments/slicer";
import * as projects from "./projects/slicer";
import * as trades from "./trades/slicer";
import * as rawItems from "./rawItems/slicer";

const rootReducer = combineReducers({
  ...users,
  ...company,
  ...departments,
  ...projects,
  ...trades,
  ...rawItems,
});

export default rootReducer;
