import apiClient from "./apiClient";

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error('No refresh token found');
  }

  try {
    // שליחת ה-refresh token לבקשה לנתיב של חידוש token
    const response = await apiClient.post(`/api/auth/refresh-token`, {
      refreshToken: refreshToken  // שים לב לשם השדה כאן - `refreshToken`
    });

    if (response.data.accessToken) {
      localStorage.setItem('accessToken', response.data.accessToken); // שמירת ה-access token החדש
      return response.data.accessToken;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Error during token refresh:', error);
    throw error;  // החזרת השגיאה להמשך טיפול
  }
};

export default refreshToken;
