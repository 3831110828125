import React, { useEffect, useState } from 'react';
import { fetchProtectedData, ProtectedDataResponse } from '../../api/login/auth';  // Import the fetch function

const ProtectedPage: React.FC = () => {
  const [data, setData] = useState<ProtectedDataResponse | null>(null); // State typed correctly
  const [errorMessage, setErrorMessage] = useState<string>('');  // Error message state

  useEffect(() => {
    const getData = async () => {
      try {
        console.log('Fetching protected data...');
        const result: ProtectedDataResponse = await fetchProtectedData();  // Use the correct type for the result
        console.log('Data fetched:', result);  // Logging the result for debugging
        setData(result);  // Set the fetched data
      } catch (error: any) {
        console.error('Error fetching protected data:', error);
        setErrorMessage(error.message || 'Failed to fetch protected data');  // Set the error message
      }
    };

    getData();  // Call the function to fetch the data
  }, []);

  return (
    <div className="protected-page">
      <h2>Protected Page</h2>
      {errorMessage ? (
        <p>{errorMessage}</p>  // Show error message if there is one
      ) : (
        <p>{data ? JSON.stringify(data) : 'Loading data...'}</p>  // Show data or loading message
      )}
    </div>
  );
};

export default ProtectedPage;
