import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/css/global.css';
import { Provider } from 'react-redux';
import store from './redux/stores';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);