import apiClient from '../login/apiClient';
import * as fetcher from '../httpHelper';
interface SowResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export const createUser = async (params: any) => {
  const token = localStorage.getItem('accessToken'); 

  console.log('Sending request with token:', token); 

  if (!token) {
    alert('Access token is missing.');
    return; 
  }

  try {
    const response = await apiClient.post('/api/users', params, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    console.log('User created successfully:', response.data); 
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to create user');
  }
};

export const searchUsers = async (params: any) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.get(`/api/users/search?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Users fetched successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to fetch users');
  }
};

export const updateUser = async (id: number, params: any) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/users/${id}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update user');
  }
};

export const deleteUser = async (id: number) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.delete(`/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete user');
  }
};

const handleError = (error: any, defaultMessage: string): SowResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
      return { success: false, error: 'Unauthorized' };
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data.message || defaultMessage };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};

export const searchUsersV2 = (params: any) => {
  return fetcher.GetFetcher("/api/users/search", params);
}

export const createUserV2 = (params: any) => {
  console.log('createUserV2', params)
  return fetcher.PostFetcher('/api/users', params);
}

export const updateUserV2 = (params: any) => {
  return fetcher.PutFetcher(`/api/users/${params.id}`, params);
}

export const deleteUserV2 = (id: number) => {
  return fetcher.DeleteFetcher(`/api/users/${id}`);
}
