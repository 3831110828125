import React from 'react';

interface SOW {
  title: string;
  building_type: string;
  subcontract_value: number;
  alternate_subcontract_value: number;
  status: string;
}

interface SowListProps {
  sows: SOW[];
  onOpenSetting: (sow: SOW) => void;
}

const SowList: React.FC<SowListProps> = ({ sows, onOpenSetting }) => {
  return (
    <section className="user-list-container">
      <h3 className="user-list-title">SOWs</h3>
      <div className="user-table-wrapper">
        <table className="user-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Building Type</th>
              <th>Sub Contract Value</th>
              <th>Alternative Subcontract Value</th>
              <th>Status</th>
              <th>Settings</th>
            </tr>
          </thead>
          <tbody>
            {sows.map((sow, index) => (
              <tr key={index}>
                <td>{sow.title}</td>
                <td>{sow.building_type}</td>
                <td>{sow.subcontract_value}</td>
                <td>{sow.alternate_subcontract_value}</td>
                <td>{sow.status}</td>
                <td>
                  <button
                    className="settings-button"
                    onClick={() => onOpenSetting(sow)}
                    title="Settings"
                  >
                    ⚙️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SowList;
