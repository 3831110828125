import { ICommon, PageResult, ResultData } from "redux/helper/types";

export interface IDepartment {
  id: number; 
  name: string; 
}

export const getAllDepartmentState: ICommon<PageResult<IDepartment[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const searchDepartmentsState: ICommon<PageResult<IDepartment[]> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const createDepartmentState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const updateDepartmentState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}

export const deleteDepartmentState: ICommon<ResultData<null> | null> = {
  isPending: false,
  isSuccess: false,
  result: null
}
