import { createContext, ReactNode, useContext, useState } from "react";

export interface AppContextType {
  selectedMenuName: string;
  selectedMenu: string[];
  setSelectedMenuName: (name: string) => void;
  setSelectedMenu: (menu: string[]) => void;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [selectedMenuName, setSelectedMenuName] = useState<string>("Dashboard");
  const [selectedMenu, setSelectedMenu] = useState<string[]>(["/dashboard"]);

  return (
    <AppContext.Provider
      value={{
        selectedMenuName,
        selectedMenu,
        setSelectedMenuName,
        setSelectedMenu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};
