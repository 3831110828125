import { useState } from 'react';
import '../../assets/css/login/forgotPassword.css';
import { forgotPassword } from '../../api/login/forgotPassword';


const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // הפונקציה שתשלח את בקשת שכחתי סיסמה לשרת
  const handleForgotPassword = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(email); // קריאה לפונקציה מה-API ששולחת את הבקשה לשרת
      console.log('Success:', response);
      setSuccessMessage('Password reset link has been sent to your email.');
      setErrorMessage('');
    } catch (error: any) {
      setErrorMessage(error.message || 'Error sending password reset email');
      setSuccessMessage('');
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-left">
        <div className="logo">MID</div>
        <h2>Reset Password</h2>
        <p>Enter your email to reset your password</p>
        <form className='forgot-password-form' onSubmit={handleForgotPassword}>
          <input
            type="email"
            placeholder="example@example.com"
            className="email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <button type="submit" className="validate-button">
            Validate Email
          </button>
        </form>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
      <div className="reset-password-right">
        <div className="background-overlay">
          <h1>MID Construction Group</h1>
          <p>Building The Future of Los Angeles</p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
