import { asyncThunkCreator } from "../helper/asyncThunkCreator";
import { 
  getAllTrades,
  searchTradeV2,
  createTradeV2,
  updateTradeV2,
  deleteTradeV2
} from "../../api/Trades/trades";

export const getAllTradesAction = asyncThunkCreator({
  actionName: "trade/getAll",
  api: getAllTrades
});

export const searchTradeAction = asyncThunkCreator({
  actionName: "trade/search",
  api: searchTradeV2
})

export const createTradeAction = asyncThunkCreator({
  actionName: "trade/create",
  api: createTradeV2,
  notif: { enable: true, message: 'Succesfully created.' }
})

export const updateTradeAction = asyncThunkCreator({
  actionName: "trade/update",
  api: updateTradeV2,
  notif: { enable: true, message: 'Succesfully updated.' }
})

export const deleteTradeAction = asyncThunkCreator({
  actionName: "trade/delete",
  api: deleteTradeV2,
  notif: { enable: true, message: 'Succesfully deleted.' }
})