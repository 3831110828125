
import apiClient from './apiClient';

// פונקציה לשליחת בקשה ל"שכחתי סיסמה"
export const forgotPassword = async (email: string) => {
  try {
    const response = await apiClient.post(`/api/auth/forgot-password`, { email }); 
    return response.data; // להחזיר את התשובה מהשרת (אם יש צורך)
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error sending password reset email');
  }
};
