import { createSlice } from "@reduxjs/toolkit";
import * as Action from "./actions";
import * as State from "./state";

const GetAllRawItems = createSlice({
  name: "getAllRawItems",
  initialState: State.getAllRawItemsState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.getAllRawItemsAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.getAllRawItemsAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.getAllRawItemsAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const GetSearchRawItem = createSlice({
  name: "searchRawItem",
  initialState: State.searchRawItemState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.searchRawItemAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.searchRawItemAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.searchRawItemAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const CreateRawItem = createSlice({
  name: "createRawItem",
  initialState: State.createRawItemState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.createRawItemAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.createRawItemAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.createRawItemAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const UpdateRawItem = createSlice({
  name: "updateRawItem",
  initialState: State.updateRawItemState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.updateRawItemAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.updateRawItemAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.updateRawItemAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const DeleteRawItem = createSlice({
  name: "deleteRawItem",
  initialState: State.deleteRawItemState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.deleteRawItemAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.deleteRawItemAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.deleteRawItemAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

export const { reset: resetCreateRawItemState } = CreateRawItem.actions;
export const { reset: resetUpdateRawItemState } = UpdateRawItem.actions;
export const { reset: resetDeleteRawItemState } = DeleteRawItem.actions;

export const getAllRawItems = GetAllRawItems.reducer;
export const searchRawItems = GetSearchRawItem.reducer;
export const createRawItem = CreateRawItem.reducer;
export const updateRawItem = UpdateRawItem.reducer;
export const deleteRawItem = DeleteRawItem.reducer;
