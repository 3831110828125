import apiClient from '../login/apiClient';

interface SowSearchParams {
  [key: string]: string | number | boolean;
}

interface SowResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export const searchSow = async (params: SowSearchParams): Promise<SowResponse | undefined> => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const query = new URLSearchParams(params as Record<string, string>).toString();
    const response = await apiClient.get(`/api/sow/search?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Sow fetched successfully:', response.data);
    return { success: true, data: response.data };
  } catch (error: any) {
    return handleError(error, 'Failed to fetch Sow');
  }
};

const handleError = (error: any, defaultMessage: string): SowResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
      return { success: false, error: 'Unauthorized' };
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data.message || defaultMessage };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};
