import React from 'react';
import '../../assets/css/Sow/sowTemplatViewer.css';

interface TemplateItem {
  id: string;
  name: string;
  type: 'Title' | 'DateTime' | 'List' | 'Signature' | 'Text';
  content: string | string[];
  settings?: { fontSize?: string };
}

interface TemplateViewerProps {
  templateItems: TemplateItem[];
  onDropItem: (value: TemplateItem) => void;
}

const TemplateViewer: React.FC<TemplateViewerProps> = ({ templateItems }) => {
  return (
    <div className="template-viewer-container">
      {/* The main form */}
      <div className="template-viewer">
        <div className="viewer-header">
          <h2>Plumbing SOW Template</h2>
        </div>

        {/* Company logo and description */}
        <div className="company-details">
          <div className="logo-placeholder">[LOGO]</div>
          <div className="company-description">
            <p>Company Name: Cleveland Cabinets</p>
            <p>Address: 123 Example Street, Cleveland, OH</p>
          </div>
        </div>

        {/* Dynamic content */}
        <div className="viewer-content">
          {templateItems.length > 0 ? (
            templateItems.map((item, index) => (
              <div key={index} className="template-item">
                {renderItem(item)}
              </div>
            ))
          ) : (
            <p>Drag items here to create your template.</p>
          )}
        </div>
      </div>

      {/* Footer buttons */}
      <div className="viewer-footer">
        <button className="page-btn">
          <i className="fas fa-plus"></i> Page
        </button>
        <button className="save-btn">Save</button>
      </div>
    </div>
  );
};

/* Function to render template items */
const renderItem = (item: TemplateItem) => {
  switch (item.type) {
    case 'Title':
      return <h1 style={{ fontSize: item.settings?.fontSize }}>{item.content}</h1>;
    case 'DateTime':
      return <p>{item.content}</p>;
    case 'List':
      return (
        <ul>
          {Array.isArray(item.content) &&
            item.content.map((listItem, idx) => <li key={idx}>{listItem}</li>)}
        </ul>
      );
    case 'Signature':
      return (
        <div style={{ border: '1px solid #ccc', padding: '10px', height: '50px' }}>
          {item.content}
        </div>
      );
    case 'Text':
      return <p>{item.content}</p>;
    default:
      return <p>Unknown item</p>;
  }
};

export default TemplateViewer;
