import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/login/HomePage';
import { LoginPage } from './pages/login/LoginPage';
import ResetPasswordPage from './pages/login/ResetPasswordPage';
import ProtectedPage from './pages/login/ProtectedPage';  
// import UserPage from './pages/users/Users';
// import Profile from './pages/profile/Profile'
// import Department from './pages/departments/Departments'
// import Company from './pages/company/Companies'
// import Trades from './pages/trades/Trades'
// import Project from './pages/project/Project';
// import Dashboard from './pages/dashboard/Dashboard';
import ForgotPassword from './pages/login/ForgotPasswordPage';
// import TemplatesSowPage from './pages/sow/TemplatesSowPage';
// import CreateSowContractPage from './pages/sow/CreateContractSowPage';
import SowList from './pages/sowTemp/SowList';
import NewSowTemplatePage from './pages/sowTemp/NewSowTemplatePage';
import ReleventChangesSowTemplate from './pages/sowTemp/ReleventChangesSowTemplatePage';

import AppLayout from './components/common/AppLayout';
import { lazy } from 'react';
import NotFound from 'pages/notFound/NotFound';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Project = lazy(() => import('./pages/project/Project'));
const SowTemplates = lazy(() => import('./pages/sowTemp/TemplatesSowPage'));
const Trades = lazy(() => import('./pages/trades/Trades'));
const Users = lazy(() => import('./pages/users/Users'));
const Departments = lazy(() => import('./pages/departments/Departments'));
const Company = lazy(() => import('./pages/company/Companies'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const SubContractors = lazy(() => import('./pages/sowTemp/CreateContractSowPage'));

const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken');
  return !!token;  
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/user-list" element={<UserPage />} /> */}
        <Route path="/home" element={<HomePage />} />
        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route path="/department" element={<Department />} /> */}
        {/* <Route path="/company" element={<Company />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        {/* <Route path="/trades" element={<Trades />} /> */}
        {/* <Route path="/projects" element={<Project />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} /> 
        {/* <Route path="/project/:id" element={<Sow/>} /> */}
        {/* <Route path="/sow-templates" element={<TemplatesSowPage />} /> */}
        {/* <Route path="/sow-contract" element={<CreateSowContractPage/>} /> */}
        <Route path="/sow-list" element={<SowList />} />
        <Route path="/new-sow-template" element={<NewSowTemplatePage/>} />
        <Route path="/relavent -changes-sow-template" element={<ReleventChangesSowTemplate/>} />

        <Route path="/" element={<AppLayout />} >
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="projects" element={<Project />} />
          <Route path="sow-templates" element={<SowTemplates />} />
          <Route path="trades" element={<Trades />} />
          <Route path="users" element={<Users />} />
          <Route path="departments" element={<Departments />} />
          <Route path="company" element={<Company />} />
          <Route path="profile" element={<Profile />} />
          <Route path="sow-contractors" element={<SubContractors />} />
        </Route>
        
        <Route
          path="/protectedPage"
          element={isAuthenticated() ? <ProtectedPage /> : <Navigate to="/user-list" />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};



export default App;
