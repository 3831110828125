import { asyncThunkCreator } from "../helper/asyncThunkCreator";
import { 
  getAllRawItemsV2,
  searchRawItemsV2,
  createRawItemsV2,
  updateRawItemsV2,
  deleteRawItemsV2
} from "../../api/Trades/rawItems";

export const getAllRawItemsAction = asyncThunkCreator({
  actionName: "rawItem/getAll",
  api: getAllRawItemsV2
});

export const searchRawItemAction = asyncThunkCreator({
  actionName: "rawItem/search",
  api: searchRawItemsV2
})

export const createRawItemAction = asyncThunkCreator({
  actionName: "rawItem/create",
  api: createRawItemsV2,
  notif: { enable: true, message: 'Succesfully created.' }
})

export const updateRawItemAction = asyncThunkCreator({
  actionName: "rawItem/update",
  api: updateRawItemsV2,
  notif: { enable: true, message: 'Succesfully updated.' }
})

export const deleteRawItemAction = asyncThunkCreator({
  actionName: "rawItem/delete",
  api: deleteRawItemsV2,
  notif: { enable: true, message: 'Succesfully deleted.' }
})