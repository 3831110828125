import { createSlice } from "@reduxjs/toolkit";
import * as Action from "./actions";
import * as State from "./state";

const SearchProjects = createSlice({
  name: "searchProjects",
  initialState: State.searchProjectsState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.searchProjectsV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.searchProjectsV2Action.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.searchProjectsV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const CreateProject = createSlice({
  name: "createProject",
  initialState: State.createProjectState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.createProjectV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.createProjectV2Action.fulfilled, (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.createProjectV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const UpdateProject = createSlice({
  name: "updateProject",
  initialState: State.updateProjectState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.updateProjectV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.updateProjectV2Action.fulfilled, (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.result = null;
    })
    .addCase(Action.updateProjectV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const DeleteProject = createSlice({
  name: "deleteProject",
  initialState: State.deleteProjectState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.deleteProjectV2Action.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.deleteProjectV2Action.fulfilled, (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.deleteProjectV2Action.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

const GetAllProjects = createSlice({
  name: "getAllProjects",
  initialState: State.getAllProjectsState,
  reducers: {
    reset: (state) => {
      state.isPending = false;
      state.isSuccess = false;
      state.result = null;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(Action.getAllProjectsAction.pending, (state) => {
      state.isPending = true;
      state.isSuccess = false;
    })
    .addCase(Action.getAllProjectsAction.fulfilled, (state, action) => {
      console.log('fulfilled', action)
      state.isPending = false;
      state.isSuccess = true;
      state.result = action.payload.data;
    })
    .addCase(Action.getAllProjectsAction.rejected, (state) => {
      state.isPending = false;
      state.isSuccess = false;
    })
  },
});

export const { reset: resetSearchProjectsState } = SearchProjects.actions;
export const { reset: resetCreateProjectState } = CreateProject.actions;
export const { reset: resetUpdateProjectState } = UpdateProject.actions;
export const { reset: resetDeleteProjectState } = DeleteProject.actions;
export const { reset: resetGetAllProjectsState } = GetAllProjects.actions;

export const searchProjectsV2 = SearchProjects.reducer;
export const createProjectV2 = CreateProject.reducer;
export const updateProjectV2 = UpdateProject.reducer;
export const deleteProjectV2 = DeleteProject.reducer;
export const getAllProjects = GetAllProjects.reducer;
