import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { GoogleOAuthProvider, GoogleLoginResponse } from '@react-oauth/google';
import '../../assets/css//login/branding.css';
import '../../assets/css//login/layout.css';
import '../../assets/css//login/login.css';
import { Branding } from '../../components/login/Branding';
import { LoginForm } from '../../components/login/LoginForm';
import { useNavigate } from 'react-router-dom';

const GOOGLE_CLIENT_ID = 'scope-of-work-sow';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  // Function to handle successful Google login response
  const handleGoogleSuccess = (response: any) => {
    navigate('/user-list');
    console.log('Google Login Success:', response);
  };

  // Function to handle failed Google login response
  const handleGoogleFailure = () => {
    console.log('Google Login Failed:');
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="container">
        <div className="left-side">
          <Branding />
        </div>
        <div className="right-side">
          <div className="login-box">
            <h2>Hello Again!</h2>
            <p>Welcome Back</p>
            <LoginForm 
              handleGoogleSuccess={handleGoogleSuccess} 
              handleGoogleFailure={handleGoogleFailure} 
            />
            {/* <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
            /> */}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};
